<template>
  <div class="container" :data-show="showOverlay.indexOf(data.status) !== -1 ? 'true' : 'false'">
    <!-- todo: beatmap-info-block 작업, show,hide 효과 작업 -->
    <div class="performance-block">
      <!-- 좌측 상단 -->
      <div class="pp-info">
        <ul>
          <li>
            <span class="info pp">pp</span>
            <IOdometer class="iOdometer data" format="(dddd).dd" :duration="5000" :value="parseFloat(data.ppIfMapEndsNow.toFixed())"/>
          </li>
          <li v-if="data.miss > 0 || data.sliderBreaks > 0">
            <span class="info if-fc">if fc</span>
            <IOdometer class="iOdometer data" format="(dddd).dd" :duration="5000" :value="parseFloat(data.noChokePp.toFixed())"/>
          </li>
        </ul>
        <ul>
          <li>
              <span class="info combo c100"></span>
              <IOdometer class="iOdometer data" :duration="odometerDuration" :value="data.c100"/>
          </li>
          <li>
              <span class="info combo c50"></span>
              <IOdometer class="iOdometer data" :duration="odometerDuration" :value="data.c50"/>
          </li>
          <li>
              <span class="info combo cmiss"></span>
              <IOdometer class="iOdometer data" :duration="odometerDuration" :value="data.miss"/>
          </li>
        </ul>
      </div>
      <!-- <span class="line top" style="--height: 70px;"></span>
      <span class="line right" style="--height: 70px;"></span>
      <span class="line bottom" style="--height: 70px;"></span>
      <span class="line left" style="--height: 70px;"></span> -->
    </div>
    <div class="beatmap-info-block">
      <!-- 하단 비트맵 정보 부분 -->
      <img ref="dummybg" style="display: none;"/>
      <div class="beatmap-image" ref="bgelement">
        <div class="ranked-status">
          <img :src="rankedStatus2Icon(data.rankedStatus)">
        </div>
      </div>
      <ul class="beatmap-info">
        <li>
          <span class="title" v-if="data.titleRoman.length <= 32">{{data.titleRoman}}</span>
          <marquee class="title" v-if="data.titleRoman.length > 32">{{data.titleRoman}}</marquee>
          <!-- <span class="title">{{data.titleRoman}}</span> -->
          <span class="line"></span>
          <span class="artist" v-if="data.artistRoman.length <= 50">{{data.artistRoman}}</span>
          <marquee class="artist" v-if="data.artistRoman.length > 50">{{data.artistRoman}}</marquee>
          <div>
            <i class="fa-solid fa-list-music"></i>
            <i class="fa-solid fa-plus"></i>
          </div>
        </li>
        <li>
          <el-slider :show-tooltip="false" :disabled="true" :max="parseInt(data.totaltime/1000)" v-model="data.time"></el-slider>
          <div class="time-block">
            <span>0:00</span>
            <span>{{seconds2Time(data.time)}}</span>
            <span>{{seconds2Time(data.totaltime/1000)}}</span>
          </div>
        </li>
        <li>
          <i class="fa-solid fa-shuffle"></i>
          <div>
            <i class="fa-solid fa-backward-fast"></i>
            <i class="fa-solid fa-pause"></i>
            <i class="fa-solid fa-forward-fast"></i>
          </div>
          <i class="fa-solid fa-repeat-1"></i>
        </li>
      </ul>
    </div>
    <div class="ur-block" :data-show-ur="data.status == 2 || data.status == 8 ? 'true' : 'false'">
      <!-- UR바 상단 -->
      <IOdometer class="ur iOdometer" :duration="odometerDuration" :value="parseFloat(data.unstableRate.toFixed(1))"/>
    </div>
  </div>
</template>

<script>
import ReconnectingWebSocket from '@/assets/js/reconnecting-websocket.js'
import IOdometer from 'vue-odometer'

export default {
  name: 'ForMe',
  components: {
    IOdometer
  },
  data () {
    return {
      showOverlay: [2, 8, 32],
      odometerDuration: 4500,
      test: 0,
      ws: null,
      data: [],
      dummybg: this.$refs.dummybg,
      keyData: {},
      urll: null,
      musicStatus: 0,
      initdata: [
        'mapStrains',
        'artistRoman',
        'titleRoman',
        'mapArtistTitle',
        'creator',
        'diffName',
        'mStars',
        'mCS',
        'mAR',
        'mOD',
        'mHP',
        'mBpm',
        'mods',
        'time',
        'totaltime',
        'status',
        'c300',
        'c100',
        'c50',
        'miss',
        'rankedStatus',
        'mapsetid',
        'status',
        'md',
        'combo',
        'keyOverlay',
        'osu_m95PP',
        'osu_m96PP',
        'osu_m97PP',
        'osu_m98PP',
        'osu_m99PP',
        'osu_mSSPP',
        'combo',
        'sliderBreaks',
        'unstableRate',
        'simulatedPp',
        'ppIfMapEndsNow',
        'noChokePp'
      ]
    }
  },
  created () {
    var vm = this

    if (localStorage.getItem('sc_port') === '') {
      window.location.replace('/')
    }

    vm.ws = new ReconnectingWebSocket(`ws://localhost:${localStorage.getItem('sc_port')}/tokens`, null, { debug: true, reconnectInterval: 3000 })
    vm.ws.onopen = function (event) {
      console.log('Connected to websocket.')
      vm.ws.send(JSON.stringify(vm.initdata))
      let lastbg

      vm.ws.onmessage = function (event) {
        vm.data = { ...vm.data, ...JSON.parse(event.data) }
        if (vm.dummybg === undefined) vm.dummybg = vm.$refs.dummybg;
        if (vm.data !== null && vm.data.mapsetid !== 1 && lastbg !== vm.data.mapsetid && vm.dummybg != null) {
          lastbg = vm.data.mapsetid
          vm.urll = `http://localhost:${localStorage.getItem('sc_port')}/backgroundImage?width=2000&mapset=${vm.data.mapsetid}&dummyData=${vm.data.md5}`
          vm.dummybg.src = vm.urll
          vm.dummybg.onload = function () {
            vm.$refs.bgelement.style.backgroundImage = `url("${vm.urll}")`
          }
        }
      }
    }
  },
  methods: {
    seconds2Time (time) {
      var hrs = ~~(time / 3600)
      var mins = ~~((time % 3600) / 60)
      var secs = ~~time % 60
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    rankedStatus2Icon (status) {
      switch (status) {
        case 4:
          return '/img/ranked-status/selection-ranked2x.png'
        case 5:
          return '/img/ranked-status/selection-approved2x.png'
        case 6:
          return '/img/ranked-status/selection-approved2x.png'
        case 7:
          return '/img/ranked-status/selection-loved2x.png'
        default:
          return '/img/ranked-status/selection-question2x.png'
      }
    },
    modsHandler () {
      var vm = this
      var temp = vm.data.mods
      while (temp.indexOf(',') !== -1) {
        temp = temp.replace(',', '')
      }
      if (temp === 'None') return 'NoMod'
      return temp
    }
  },
  watch: {
    
  }
}
</script>

<style scoped lang="scss">
.container { 
  width: 100vw;
  height: 100vh;
  color: var(--base-color);
//   background: center / cover no-repeat url("/img/rika.png");
  overflow: hidden;

  .performance-block { 
    position: absolute; 
    left: 2vw;
    top: 23.5vh;
    text-align: left;
    transition: transform 200ms ease, opacity 200ms ease;
    z-index: -1;
    h1 {
      margin-bottom: 5px;
      top: -22px;
      left: 10px;
      position: absolute;
    }
    span.line {
      width: 100%;
      height: var(--height);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
    }
    span.line.top {
      border-top: 3px solid var(--base-color);
      clip: rect(-813px, 559px, 75px, 80px);
    }
    span.line.right {
      border-inline-end: 3px solid var(--base-color);
      clip: rect(-571px, 754px, 45px, -17px);
      left: -1.5px;
    }
    span.line.bottom {
      border-bottom: 3px solid var(--base-color);
      clip: rect(-857px, 180px, 75px, -204px);
      top: -2px;
    }
    span.line.left {
      border-inline-start: 3px solid var(--base-color);
      clip: rect(30px, 275px, 109px, -53px);
      left: 0px;
    }
    div.pp-info { 
      transform: scale(1.15);
      background: #1d1b26;
      color: white;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      padding: 10px 20px;

      ul {
        display: flex;

        &:nth-child(2) {
          margin-top: -2.5px;
        }
      }

      li, div {
        padding: 7.5px 10px 2.5px 10px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          padding: 4px 10px;
          border-radius: 20px;
          font-weight: bold;
          color: rgb(34, 34, 34);
          font-size: 15px;
          margin-bottom: 5px;
        }

        .combo {
          margin-bottom: 0;
        }
        
        .data {
          font-size: 13px;
          font-weight: bold;
          line-height: 1.5;
        }
        .c100 { background: #8bff53; }
        .c50 { background: #fff953; }
        .cmiss { background: #ff536f; }
        .pp { background: #81c8fa; }
        .if-fc { background: #a4a7ff; }
      }
    }
    i.fa-star {
      position: absolute;
      bottom: -10px;
      right: -10px;
      font-size: 22px;
      transform: rotate(20deg);
      animation: star-shining 6s 4s infinite ease-in-out;
      transition: text-shadow 1s ease-in-out;
    }
  }

  .mods-block {
    position: absolute;
    right: 34vw;
    bottom: 17vh;
    font-weight: bold;
    text-align: right;
    transition: transform 200ms ease, opacity 200ms ease;
    z-index: -1;
    span {
      color: white;
      font-size: 20px;
    }
    span:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -5px;
      width: 120%;
      height: 2px;
      background-color: var(--base-color);
      transform: translate(-50%, -50%);
    }
    i.fa-star {
      position: absolute;
      left: -21px;
      bottom: -10px;
      transform: rotate(-10deg);
      animation: star-shining 6s 2s infinite ease-in-out;
      transition: text-shadow 1s ease-in-out;
    }
  }

  .pp-if-block {
    position: absolute;
    right: 22.5vw;
    top: 30vh;
    text-align: right;
    transition: transform 200ms ease, opacity 200ms ease;
    z-index: -1;
    h1 {
      top: -3vh;
      right: 0.75vw;
      position: absolute;
      text-shadow: 0 0 3px #000;
    }

    ul {
      display: grid;
      flex-flow: wrap;
      // max-width: 240px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
      background: #1d1b26;
      padding: 5px 10px;
      border-radius: 15px;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        padding: 5px 10px;
        &:not(:nth-child(3n)) {
          margin-right: 10px;
        }
        .data {
          font-size: 13px;
          color: #fff;
        }
      }
    }
    .fa-star {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 22px;
      transform: rotate(20deg);
      animation: star-shining 6s 7.25s infinite ease-in-out;
      transition: text-shadow 1s ease-in-out;
    }
    span.line {
      width: 100%;
      height: var(--height);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
    }
    span.line.top {
      border-top: 3px solid var(--base-color);
      clip: rect(-867px, 88px, 316px, -369px);
    }
    span.line.right {
      border-inline-end: 3px solid var(--base-color);
      clip: rect(42px, 726px, 460px, -729px);
      left: -1.5px;
    }
    span.line.bottom {
      border-bottom: 3px solid var(--base-color);
      clip: rect(-668px, 875px, 399px, 148px);
      top: -3px;
    }
    span.line.left {
      border-inline-start: 3px solid var(--base-color);
      clip: rect(-39px, 361px, 43px, -199px);
      left: 0px;
    }
  }
  
  .beatmap-info-block {
    $img-size: 150px;

    position: absolute;
    bottom: 1vh;
    right: 0.5vw;
    text-align: left;
    transition: transform 200ms ease, opacity 200ms ease;
    display: flex;
    align-items: center;
    z-index: 2123123;

    div.beatmap-image {
      width: $img-size;
      height: $img-size;
      background: center / cover no-repeat;
      background-image: var(--bg);
      position: relative;
      margin-right: 10px;
      border-radius: 12px;
      transition: background-image 500ms ease-in-out;
      div.ranked-status {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 5px;
        top: 5px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background: #1d1b26;
        img {
          height: 20px;
        }
      }
    }
    ul.beatmap-info {
      width: 350px;
      max-width: 350px;
      height: 140px;
      background: #2f2c3d;
      border-radius: 15px;
      padding: 5px 10px;
      li{
        &:nth-child(1) {
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          overflow: hidden;
          .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 5px;
            text-shadow: 0 0 5px black;
            line-height: 1.5;
            width: 350px;
            height: 30px;
            white-space: nowrap;
          }
          span.line {
            width: 100%;
            height: 1px;
            background: var(--base-color);
            margin-bottom: 5px;
          }
          .artist {
            font-size: 15px;
            font-weight: bold;
            text-shadow: 0 0 5px black;
          }
          div {
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;
            top: -4px;
          }
        }
        &:nth-child(2) {
          position: relative;
          top: -3px;
          div.time-block {
            position: relative;
            top: -10px;
            display: flex;
            justify-content: space-between;
            span{
              font-size: 14px;

              &:nth-child(2) {
                position: relative;
                // top: -2.5px;
                color: white;
                font-weight: bold;
              }
            }
          }
        }
        &:nth-child(3) {
          position: relative;
          display: flex;
          justify-content: space-between;

          div {
            display: flex;
            justify-content: space-around;
            width: 180px;
          }
        }
      }
    }
  }

  .diff-block {
    z-index: -1;
    ul {
      display: flex;
      position: absolute;
      top: 10vh;
      right: 51vw;
      li {
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 35px;
        &:nth-child(1) {
          right: 0.75vw;
          .diff { margin-bottom: 1px; }
        }
        &:nth-child(2) {
          .diff { margin-bottom: 1px; }
        }
        &:nth-child(3) {
          left: 3vw;
          .diff { margin-bottom: 1px; }
        }
        &:nth-child(4) {
          left: 6vw;
          .diff { margin-bottom: 1px; }
        }
        &:nth-child(5) {
          left: 9vw;
          .diff { margin-bottom: 1px; }
        }
        span {
          font-weight: bold;
          margin-bottom: 3.5px;
          color: var(--base-color);
        }
        .diff {
          font-size: 13px;
          color: white;
        }
      }
    }
    div.star-block {
      top: 13.25vh;
      right: 53.1vw;
      position: absolute;
      i {
        &:nth-child(1) {
          transform: rotate(10deg);
          animation: star-shining 6s 4s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: -1.5vw;
          top: -2vh;
          font-size: 1.05em;
          z-index: -2;
        }
        &:nth-child(2) {
          transform: rotate(40deg);
          animation: star-shining 6s 1s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: 1.8vw;
          top: -2.5vh;
        }
        &:nth-child(3) {
          transform: rotate(-20deg);
          animation: star-shining 6s 6s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: 4.5vw;
          top: -2vh;
          font-size: 0.9em;
        }
        &:nth-child(4) {
          transform: rotate(10deg);
          animation: star-shining 6s 7.25s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: 7.05vw;
          top: -2.25vh;
          font-size: 1.2em;
        }
        &:nth-child(5) {
          animation: star-shining 6s 0.25s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: 9.75vw;
          top: -1.6vh;
          font-size: 0.8em;
        }
        &:nth-child(6) {
          transform: rotate(15deg);
          animation: star-shining 6s 10s infinite ease-in-out;
          transition: text-shadow 1s ease-in-out;
          position: absolute;
          left: 13vw;
          top: -2.5vh;
          font-size: 1.4em;
        }
      }
    }
    div.line-block {
      top: 13.25vh;
      right: 53.1vw;
      position: absolute; 
      span {
        display: block;
        width: 67.5px;
        height: 3px;
        background: #535061b0;
        z-index: -3;
        &:nth-child(1) {
          position: absolute;
          left: -1vw;
          top: -1.7vh;
          transform: rotate(-10deg);
        }
        &:nth-child(2) {
          position: absolute;
          left: 2vw;
          top: -1.7vh;
          width: 64px;
          transform: rotate(10deg);
        }
        &:nth-child(3) {
          position: absolute;
          left: 4.5vw;
          top: -1.7vh;
          width: 64px;
          transform: rotate(-7.5deg);
        }
        &:nth-child(4) {
          position: absolute;
          left: 7.25vw;
          top: -1.7vh;
          width: 60px;
          transform: rotate(10deg);
        }
        &:nth-child(5) {
          position: absolute;
          left: 10vw;
          top: -1.5vh;
          width: 60px;
          transform: rotate(-7.5deg);
        }
      }
    }
  }
  
  .star-rating {
    position: absolute;
    right: 29vw;
    bottom: 30vh;
    z-index: -1;
    font-size: 18px;
    font-weight: bold;
    transform: rotateZ(-45deg);
  }

  .ur-block {
    position: absolute;
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    left: 48%;
    transform: translateX(100% - 50%);
    bottom: 4vh;
    transition: transform 200ms ease, opacity 200ms ease;
    z-index: -1;
    .ur {
      width: 45px;
      max-width: 45px;
      padding: 5px 12px;
      text-align: center;
      color: rgb(58, 58, 58);
      background: #fff;
      border-radius: 15px;
      font-weight: bold;
    }
  }

  &[data-show=false] {
    .performance-block {
      animation: close-left2right 250ms 0ms 1 ease forwards;
    }
    .mods-block {
      animation: close-right2left 250ms 500ms 1 ease forwards;
    }
    .pp-if-block {
      animation: close-right2left 250ms 250ms 1 ease forwards;
    }
    .ur-block {
      animation: close-bottom2top 250ms 750ms 1 ease forwards;
    }
  }
  &[data-show=true] {
    .performance-block {
      animation: open-left2right 250ms 0ms 1 ease forwards;
    }
    .mods-block {
      animation: open-right2left 250ms 0ms 1 ease forwards;
    }
    .pp-if-block {
      animation: open-right2left 250ms 0ms 1 ease forwards;
    }
    .ur-block[data-show-ur=false] { opacity: 0; transform: translateY(250px); }
    .ur-block[data-show-ur=true] {
      animation: open-bottom2top 250ms 0ms 1 ease forwards;
    }
  }

  // animation 부분
  @keyframes star-shining {
    0% { text-shadow: 0 0 5px var(--base-color); }
    80% { text-shadow: 0 0 5px var(--base-color); }
    90% { text-shadow: 0 0 15px var(--base-color), 0 0 15px var(--base-color);}
    100% { text-shadow: 0 0 5px var(--base-color); }
  }
  @keyframes text-scroll {
    0% { transform: translateX(0px); }
    100% { transform: translateX(-100%); }
  }
  @keyframes open-left2right {
    0% { opacity: 0; transform: translateX(-250px); }
    100% { opacity: 1; transform: translateX(0); }
  }
  @keyframes open-right2left {
    0% { opacity: 0; transform: translateX(250px); }
    100% { opacity: 1; transform: translateX(0); }
  }
  @keyframes open-top2bottom {
    0% { opacity: 0; transform: translateY(-250px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes open-bottom2top {
    0% { opacity: 0; transform: translateY(250px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes close-left2right {
    0% { opacity: 1; transform: translateX(0); }
    100% { opacity: 0; transform: translateX(-250px); }
  }
  @keyframes close-right2left {
    0% { opacity: 1; transform: translateX(0); }
    100% { opacity: 0; transform: translateX(250px); }
  }
  @keyframes close-top2bottom {
    0% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-250px); }
  }
  @keyframes close-bottom2top {
    0% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(250px); }
  }
}
</style>
