<template>
  <div>
    <!-- Enabled = {{keyEnabled}} <br>
    K1 = {{keyK1}} <br>
    K2 = {{keyK2}} <br> -->
      <!-- <Key v-cloak /> -->
      <!-- <Key v-cloak /> -->

    <!-- <div v-if="keyEnabled" class="k1">
      <component v-for="(item, index) in k1" :class="'cavask1'" :style="'z-index: '+ k1Zindex" :is="item" :name="0" :enabled="keyK1" v-bind:key="index"></component>
    </div>
    <div v-if="keyEnabled" class="k2">
      <component v-for="(item, index) in k2" :class="'cavask2'" :style="'z-index: '+ k2Zindex" :is="item" :name="1" :enabled="keyK2" v-bind:key="index"></component>
    </div>
    <div class="gradient top"></div>
    <div class="gradient bottom"></div> -->
    <canvas ref="canvas1" width="200" height="700" style="background-color: transparent;"></canvas>
    <canvas ref="canvas2" width="200" height="700" style="left: 125px; background-color: transparent;"></canvas>
  </div>
</template>

<script>
import ReconnectingWebSocket from '@/assets/js/reconnecting-websocket.js'
import Key from '../components/Key.vue'
import * as THREE from 'three'

export default {
  name: 'key_overlay',
  // components: {
  //   Key
  // },
  data () {
    return {
      childLimit: 8,
      deleteInterval: 500,
      int1: null,
      int2: null,
      showOverlay: [2, 8, 32],
      odometerDuration: 4500,
      test: 0,
      shapes1: [],
      shapes2: [],
      y1: 0,
      y2: 0,
      k1: [],
      k2: [],
      ws: null,
      data: [],
      keyEnabled: false,
      keyK1: false,
      keyK2: false,
      dumybg: null,
      bgelement: null,
      urll: null,
      musicStatus: 0,
      initdata: [
        'status',
        'rawStatus',
        'rankedStatus',
        'mapsetid',
        'status',
        'md',
        'keyOverlay',
        'time'
      ]
    }
  },
  created () {
    var vm = this

    if (localStorage.getItem('sc_port') === '') {
      window.location.replace('/')
    }

    vm.ws = new ReconnectingWebSocket(`ws://localhost:${localStorage.getItem('sc_port')}/tokens`, null, { debug: true, reconnectInterval: 3000 })
    vm.ws.onopen = function (event) {
      console.log('Connected to websocket.')
      vm.ws.send(JSON.stringify(vm.initdata))
      let lastbg

      vm.ws.onmessage = function (event) {
        vm.data = { ...vm.data, ...JSON.parse(event.data) }
        if (vm.data !== null && vm.data.mapsetid !== 1 && lastbg !== vm.data.mapsetid && vm.dummybg != null) {
          lastbg = vm.data.mapsetid
          vm.urll = `http://localhost:${localStorage.getItem('sc_port')}/backgroundImage?width=2000&mapset=${vm.data.mapsetid}&dummyData=${vm.data.md5}`
          vm.dummybg.src = vm.urll
          vm.dummybg.onload = function () {
            vm.bgelement.style.backgroundImage = `url("${vm.urll}")`
          }
          vm.keyEnabled = vm.data.keyOverlay.Enabled
          vm.keyK1 = vm.data.keyOverlay.K1Pressed
          vm.keyK2 = vm.data.keyOverlay.K2Pressed
        }
      }
    }
  },
  mounted() {
    this.drawShape(1)
    // this.drawShape(2)
  },
  methods: {
    drawShape(canvasNumber) {
                    const canvas = this.$refs[`canvas${canvasNumber}`];
                    const ctx = canvas.getContext('2d');
                    const shapes = this[`shapes${canvasNumber}`];

                    // Clear the canvas
                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    // Draw existing shapes and move them down
                    shapes.forEach((shape, index) => {
                        if (shape.y < canvas.height) {
                            ctx.fillStyle = shape.color;
                            ctx.fillRect(shape.x, shape.y, 100, 100);
                            shape.y += 1.5; // Move the shape down at a fixed speed of 2
                        } else {
                            // Remove the shape from the array if it's out of the canvas
                            shapes.splice(index, 1);
                        }
                    });

                    if (this[`keyK${canvasNumber}`]) {
                        // Create a new shape
                        const newShape = {
                            x: 50,
                            y: 0, // Start at the top
                            color: 'red',
                        };
                        shapes.push(newShape);
                    }

                    // Request the next frame
                    requestAnimationFrame(() => this.drawShape(canvasNumber));
                }
  },
  watch: {
    data () {
      if (this.showOverlay.indexOf(this.data.status) !== -1) {
        this.keyEnabled = JSON.parse(this.data.keyOverlay).Enabled
        this.keyK1 = JSON.parse(this.data.keyOverlay).K1Pressed
        this.keyK2 = JSON.parse(this.data.keyOverlay).K2Pressed
        if (this.keyK1 === true) {
          this.k1.push(Key)
          this.k1Zindex++
        }
        if (this.keyK2 === true) {
          this.k2.push(Key)
          this.k2Zindex++
        }
      } 
      if (this.data.time < 0) {
        this.keyEnabled = false
        this.keyK1 = false
        this.keyK2 = false
        var a = document.getElementsByClassName('k1')[0].removeChild
        a = document.getElementsByClassName('k2')[0].removeChild
        this.k1Zindex = 2
        this.k2Zindex = 2
      }
    },
    k1 () {
      var a = document.getElementsByClassName('cavask1')
      if (a.length > this.childLimit) {
        // var tmpp = a.length
        for (let i = 0; i < this.childLimit; i++) {
          if (a[0] !== undefined) a[0].remove()
        }
        this.k1Zindex = 2
        // console.log('k1 10개 초?과 | 갯수:', tmpp, '| 처리 후 갯수:', a.length)
      }
    },
    k2 () {
      var a = document.getElementsByClassName('cavask2')
      if (a.length > this.childLimit) {
        // var tmpp = a.length
        for (let i = 0; i < this.childLimit; i++) {
          if (a[0] !== undefined) a[0].remove()
        }
        this.k2Zindex = 2
        // console.log('k2 10개 초?과 | 갯수:', tmpp, '| 처리 후 갯수:', a.length)
      }
    },
    keyK1(newKeyK1) {
        if (newKeyK1) {
            this.drawShape(1);
        }
    },
    keyK2(newKeyK2) {
        if (newKeyK2) {
            this.drawShape(2);
        }
    }
  }
}
</script>

<style>
body, div.app { background: #000; color: white; margin: 0; }

div.k1, div.k2 { position: relative; margin: 0; display: block; top: -20px; }
div.k1 { left: -100px; }
div.k2 { left: 10px; }
canvas { position: absolute; }

div.gradient { display: block; position: absolute; width: 400px; height: 100px; background: linear-gradient(to top, transparent, 50%, rgba(0, 0, 0, 0.75));}
div.gradient.bottom { top: 400px; height: 200px; transform: rotateX(180deg);}
</style>
