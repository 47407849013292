<template>
  <div>
    <canvas :width="width" :height="height" class="canvas0"></canvas>
    <canvas :width="width" :height="height" class="canvas1"></canvas>
    <canvas :width="width" :height="height" class="canvas2"></canvas>
  </div>
</template>

<script>
export default {
  name: 'key',
  props: {
    name: {
      type: Number,
      default: 0
    },
    enabled: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 220
    },
    height: {
      type: Number,
      default: 600
    },
    size: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      enable: true,
      Pos0: 25,
      Pos1: 20,
      Pos2: -10,
      count: 0,
      speed: 20
    }
  },
  watch: {
    enabled () {
      this.enable = false
    }
  },
  methods: {
    drawFront () {
      if (this.enable !== true) {
        this.ctx0.clearRect(0, 0, this.size, this.size)
        this.ctx0.beginPath()
        this.ctx0.fillRect(this.size, this.Pos0, this.size, this.size)
        this.ctx0.fill()
        this.Pos0 += this.speed
      }
      requestAnimationFrame(this.drawFront)
    },
    drawMain () {
      this.ctx1.clearRect(0, 0, this.size, this.size)
      this.ctx1.beginPath()
      // this.ctx1.fillRect(this.size, this.Pos1, this.size, this.size)
      // var gradient = this.ctx1.createLinearGradient(0, 0, 0, 170)
      // gradient.addColorStop(0, 'rgba(0,0,0,1)')
      // gradient.addColorStop(0.25, 'rgba(255,255,255,0.75)')
      // gradient.addColorStop(0.75, 'rgba(255,255,255,0.75)')
      // gradient.addColorStop(1, 'rgba(0,0,0,1)')
      this.ctx1.fillRect(this.size, this.Pos1, this.size, this.size)
      this.ctx1.fill()
      this.Pos1 += this.speed
      requestAnimationFrame(this.drawMain)
    },
    drawBack () {
      if (this.enable !== true) {
        this.ctx2.clearRect(0, 0, this.size, this.size)
        this.ctx2.beginPath()
        this.ctx2.fillRect(this.size, this.Pos2, this.size, this.size)
        this.ctx2.fill()
        this.Pos2 += this.speed
      }
      requestAnimationFrame(this.drawBack)
    }
  },
  mounted () {
    this.ctx0 = this.$el.children[0].getContext('2d')
    this.ctx1 = this.$el.children[1].getContext('2d')
    this.ctx2 = this.$el.children[2].getContext('2d')
    this.ctx0.fillStyle = 'black'
    this.ctx1.fillStyle = 'rgba(255,255,255,100)'
    this.ctx2.fillStyle = 'black'
    this.drawFront()
    this.drawMain()
    this.drawBack()
  }
}
</script>

<style scoped>
.canvas {
  border: none;
}
</style>
